import * as React from "react";

import classNames from "classnames";

import Header from "../components/header";
import Footer from "../components/footer";
import Gallery from "../components/gallery";

import pic1 from "../assets/images/treatment/1.jpg";
import pic2 from "../assets/images/treatment/2.png";
import pic3 from "../assets/images/treatment/3.jpg";
import pic4 from "../assets/images/treatment/4.png";
import GalleryHeader from "../components/galleryheader";

const galleryColumns = [
  [pic3, pic4],
  [pic2],
  [pic1]
];

const WindowTreatmentsPage = () => (
  <React.Fragment>
    <Header />
      <GalleryHeader title="Window Treatments" />
      <Gallery columns={galleryColumns} />
    <Footer />
  </React.Fragment>
);

export default WindowTreatmentsPage;
